import { render, staticRenderFns } from "./shoubottom.vue?vue&type=template&id=9cfebae4&scoped=true&"
import script from "./shoubottom.vue?vue&type=script&lang=js&"
export * from "./shoubottom.vue?vue&type=script&lang=js&"
import style0 from "@/styles/phone/bottom.less?vue&type=style&index=0&id=9cfebae4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cfebae4",
  null
  
)

export default component.exports