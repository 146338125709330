<!--  -->
<template>
    <div class="bottom">
        <div class="bottomcont">
            <img src="../assets/some/logo.png" />
            <div class="bottomcontinfo">
                <div class="bottomcontinfoitem">
                    <span>Telephone:</span> &nbsp;+86-21-62377015
                </div>
                <div class="bottomcontinfoitem">
                    <span>Emails:</span>&nbsp;Weiqiuling@junkotech.com
                </div>
                <div class="bottomcontinfoitem">
                    <span>Company Address:</span>&nbsp;
                    Room 1008，Xinda building,No.322Xianxia Road,Changning District,Shanghai
                </div>
            </div>
        </div>
        <div class="bottomcontbtn">Copyright©Shanghai Junko New Energy Technology Co.Ltd Reserved.</div>
    </div>
  </template>
  <script>
    export default {
        name: "shoubottom",
        components: {},
        data() {
            return {}
        },
        computed: {},// 监听属性 类似于data概念
        watch: {},// 监控data中的数据变化
        filters: {},
        methods: { 
            navPage(val) {
                if(val == '1') {
                    this.$router.push('/homewe')
                }
                if(val == '2') {
                    this.$router.push('/homeChan')
                }
                if(val == '3') {
                    this.$router.push('/homeYing')
                }
                if(val == '4') {
                    this.$router.push('/homeWo')
                }
                if(val == '6') {
                    this.$router.push('/home')
                }
            },
        },//方法集合
        created() {},// 生命周期 - 创建完成（可以访问当前this实例）
        mounted() {},//生命周期 - 挂载完成（可以访问DOM元素）
        beforeCreate() {}, // 生命周期 - 创建之前
        beforeMount() {}, // 生命周期 - 挂载之前
        beforeUpdate() {}, // 生命周期 - 更新之前
        updated() {}, // 生命周期 - 更新之后
        beforeDestroy() {}, // 生命周期 - 销毁之前
        destroyed() {}, // 生命周期 - 销毁完成
        activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
    };
  </script>
  <style lang="less" scoped src="@/styles/phone/bottom.less"></style>
  <style lang="less" scoped>

  </style>
  
  
  
  